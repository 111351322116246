import Axios from "axios";

export interface IStakeholder {
  id: number;
  agency: string;
  country: number;
}

export interface IAuctionStakeholder {
  country_geoname_id: string;
  agency: string;
}

interface IStakeholdersList {
  count: number;
  next: string;
  previous: string;
  results: IStakeholder[];
}

export async function getAuctionStakeholders(auctionId: number) {
  const response = await Axios.get<Promise<IStakeholdersList>>(
    "/api/stakeholders/",
    {
      params: {
        auction: auctionId,
      },
    }
  );
  return response.data;
}
