import * as assets from "./assets";
import * as auctions from "./auctions";
import * as bids from "./bids";
import * as calendar from "./calendar";
import * as deposits from "./deposits";
import * as disposalRequests from "./disposalRequests";
import * as files from "./files";
import * as lots from "./lots";
import * as pages from "./pages";
import * as stakeholders from "./stakeholders";
import * as users from "./users";
import * as utils from "./utils";

export const API = {
  assets,
  auctions,
  bids,
  calendar,
  deposits,
  disposalRequests,
  files,
  lots,
  pages,
  users,
  utils,
  stakeholders,
};
