import React from "react";
import { useTranslation } from "react-i18next";
import { FieldArray, useFormikContext } from "formik";
import styled from "styled-components";

import {
  Button,
  ButtonTheme,
  Label,
  SelectField,
  TCreateAuctionFormValues,
} from "@components";
import { FormError } from "@components/FormError";
import { device } from "@styles/breakpoints";
import { ICountry, STAKEHOLDER_AGENCIES } from "@utils";

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: ${({ theme }) => theme.tripleMargin};

  & > *:not(:last-child) {
    margin-right: ${({ theme }) => theme.tripleMargin};
  }

  @media ${device.tablet} {
    flex-direction: column;

    & > *:not(:last-child) {
      margin-right: 0;
    }
  }
`;

const Title = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 12rem 12rem;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.smallestMargin};
`;

const Error = styled.div`
  margin-bottom: ${({ theme }) => theme.tripleMargin};
`;

type Props = {
  countries: ICountry[];
};

const EMPTY_STATE = {
  country_geoname_id: "",
  agency: "",
};

export const Stakeholders = ({ countries }: Props) => {
  const { t } = useTranslation();
  const {
    values: { stakeholders },
  } = useFormikContext<TCreateAuctionFormValues>();

  return (
    <>
      {countries && (
        <FieldArray
          name="stakeholders"
          render={(helpers) => (
            <>
              <Title>
                <Label>{t("auctionForm.stakeholderTitle")}</Label>
                <Button
                  type="button"
                  theme={ButtonTheme.Blue}
                  onClick={() => {
                    helpers.push(EMPTY_STATE);
                  }}
                >
                  {t<string>("auctionForm.stakeholderAdd")}
                </Button>
              </Title>
              {stakeholders && (
                <>
                  {stakeholders.map((stakeholder, index) => (
                    <Row>
                      <SelectField
                        name={`stakeholders[${index}].country_geoname_id`}
                        placeholder={t("auctionForm.stakeholdersPlaceholder")}
                        options={countries.map((country) => ({
                          label: country.name,
                          value: country.geoname_id,
                        }))}
                      />
                      <SelectField
                        name={`stakeholders[${index}].agency`}
                        placeholder={t("auctionForm.stakeholderAgency")}
                        options={STAKEHOLDER_AGENCIES.map((agency) => ({
                          label: agency,
                          value: agency,
                        }))}
                      />
                      <Button
                        type="button"
                        disabled={stakeholders.length === 1}
                        theme={ButtonTheme.Quaternary}
                        onClick={() => {
                          helpers.remove(index);
                        }}
                        style={{ marginTop: "6px" }}
                      >
                        {t<string>("auctionForm.stakeholderDelete")}
                      </Button>
                    </Row>
                  ))}
                </>
              )}
            </>
          )}
        />
      )}
      <Error>
        <FormError name="deposits" />
      </Error>
    </>
  );
};
