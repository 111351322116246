export enum StakeholderAgencies {
  UN = "UN",
  UNICEF = "UNICEF",
  UNCTAD = "UNCTAD",
  UNDP = "UNDP",
  UNEP = "UNEP",
  UNFPA = "UNFPA",
  UNRWA = "UNRWA",
  UNU = "UNU",
  WFP = "WFP",
  UNHCR = "UNHCR",
  UN_HABITAT = "UN-Habitat",
  OCHA = "OCHA",
  ITC = "ITC",
  UNOPS = "UNOPS",
  UN_WOMEN = "UN Women",
  ILO = "ILO",
  FAO = "FAO",
  UNESCO = "UNESCO",
  IFAD = "IFAD",
  UNIDO = "UNIDO",
}

export const STAKEHOLDER_AGENCIES = Object.values(StakeholderAgencies);
